.App-header {
  height: 100px;
}
.header-con{
  width: 1300px;
  margin: 0 auto;
  padding: 20px 0;
}
.header-con img{
  width: 65px;
  height: 65px;
}
.content{
  width: 1300px;
  margin: 50px auto 150px auto;
}
.flexRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my{
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.m-logo{
  width: 5rem;
  margin-right: 10px;
}
.p1{
  width: 500px;
  margin-top: 120px;
}
.p2{
  width: 600px;
}
.btn{
  display: flex;
  align-items: center;
  width: 272px;
  height: 72px;
  justify-content: center;
  background: #FF9900;
  border: 1px solid #E18804;
  font-weight: bold;
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
  margin: 50px 0 0 40px;
}
.btn:hover{
  opacity: .8;
}
.btn img{
  margin-right: 10px;
  width: 36px;
}
.btn2{
  margin: 150px auto;
}
.list{
  text-align: center;
}
.bg{
  width: 1180px;
  height: 1111px;
  margin: 20px auto;
  background: url('./assets/bg.png') 0 0 no-repeat;
}
.swpier-box{
  width: 600px;
  height: 1033px;
  margin: 0 auto 0 220px;
}
.swpier-box img{
  width: 100%;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box{
  width: 480px;
  height: 414px;
  background: #FFFFFF;
  box-shadow: 0px 12px 14px 1px rgba(0,0,0,0.16);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 40px;
}
.box img{
  width: 80px;
  margin-bottom: 20px;
}
.footer{
  height: 168px;
  background: rgba(253, 230, 214, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}
.img-box{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
}
.img-box img{
  width: 305px;
}